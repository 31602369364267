import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './nav.css';
import { Logo } from '../logo/Logo';

export const Nav = () => {

    const navRef = useRef()

    const [navElementVisible, setNavlementVisible] = useState();
    const [mobileMenu, setMobileMenu] = useState(false)
    const [activeLink, setActiveLink] = useState(false)

    const hideMobileMenu = () => {
        setMobileMenu(!mobileMenu)
    }


    // Close mobile menu when a navigation link is clicked
    const closeMobileMenu = () => {
        setMobileMenu(false);
    };

    const updateActiveLink = (value) => {
        setActiveLink(value)
    }


    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0]
            setNavlementVisible(entry.isIntersecting)

        })
        observer.observe(navRef.current)
    })




    return (
        <nav className="navbar">
            <div className="navbar_container" ref={navRef}>
                <div id="/#hero" className={`${navElementVisible} ? logo_container show_logo : logo_container hide_logo`}>
                    <Logo className="navbar_logo" />
                </div>
                <div className={`navbar_toggle ${mobileMenu && 'is_active'}`} id="mobile_menu" onClick={hideMobileMenu}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
                <ul className={`navbar_menu ${navElementVisible ? 'show_menu' : 'hide_menu'} ${mobileMenu ? 'active' : ''}`}>
                    <li className="navbar_item">
                        <a href="/#about_section" className={activeLink === 'About Me' ? 'active navbar_links' : 'navbar_links'}
                            onClick={() => { updateActiveLink('About Me'); closeMobileMenu(); }}>About Me</a>
                    </li>
                    <li className="navbar_item">
                        <a href="/#expertise" className={activeLink === 'Expertise' ? 'active navbar_links' : 'navbar_links'}
                            onClick={() => { updateActiveLink('Expertise'); closeMobileMenu(); }}>Expertise</a>
                    </li>
                    <li className="navbar_item">
                        <a href="/#projects" className={activeLink === 'Projects' ? 'active navbar_links' : 'navbar_links'}
                            onClick={() => { updateActiveLink('Projects'); closeMobileMenu(); }}>Projects</a>
                    </li>
                    <li className="navbar_item">
                        <a href="/#contact" className={activeLink === 'Contact' ? 'active navbar_links' : 'navbar_links'}
                            onClick={() => { updateActiveLink('Contact'); closeMobileMenu(); }}>Contact</a>
                    </li>
                    <li className="navbar_item">
                        <Link to="/resume" className="navbar_links nav_button" onClick={() => closeMobileMenu()}>Resume</Link>
                    </li>
                </ul>
            </div>
        </nav>

    )
}
