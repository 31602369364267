import React, { useState, useEffect, useCallback } from 'react'
import { projects } from '../projectInfoApi';
import { skill_builders } from '../projectInfoApi';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './project_details.css'

export const ProjectDetails = () => {

    let { project: slugTitle } = useParams();
    const [projectDataInFocus, setProjectDataInFocus] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(null)



    const getProjectData = useCallback(async () => {
        setIsLoading(true)
        try {
            let inFocus = projects.find((pm) => pm.slug === slugTitle)
            setProjectDataInFocus(inFocus);

        } catch (error) {
            setIsError(error)
        } finally {
            setIsLoading(false)
        }
    }, [slugTitle])


    const getSkillBuilderData = useCallback(async () => {
        setIsLoading(true)
        try {
            let inFocus = skill_builders.find((pm) => pm.slug === slugTitle)
            setProjectDataInFocus(inFocus);

        } catch (error) {
            setIsError(error)
        } finally {
            setIsLoading(false)
        }
    }, [slugTitle])



    useEffect(() => {
        if (slugTitle === 'interview-prep') {
            getSkillBuilderData()

        } else {
            getProjectData()
        }


    }, [projectDataInFocus])


    if (isLoading) {
        return <div style={{ color: 'white' }}>Loading Data...</div>;
    }

    if (isError) {
        return <div style={{ color: 'white' }}>Error loading project data.</div>;
    }

    if (!projectDataInFocus) {
        return <div style={{ color: 'white' }}>No project found.</div>;
    }

    const { title, description, imgUrl, techStack, signIn, password, overview, painPoints, githubLink, liveUrl, icon, id } = projectDataInFocus;

    const justOverview = !overview ? " " : overview[0].split(/\r?\n/)
    const justPainPoints = !painPoints ? " " : painPoints
    return (
        <section className='projectDetailsSection'>
            <div className="projectDetailsSectionTitle">
                <h2>Project Details</h2>
            </div>
            <div className="container">
                <div className="projectDetailsContainer">
                    <div className="projectDetailsImgContainer">
                        <img src={imgUrl} alt="" />
                    </div>
                    <div className="projectDetailsTitleContainer">
                        <h3>{title}</h3>
                        <h4>{description}</h4>
                    </div>
                    <div className="projectDetailsTechStackContainer">
                        {techStack?.map(item => (
                            <div key={item.id}>{item.icon}</div>
                        ))}
                    </div>
                    <div className="projectDetailsDemoSignInContainer">
                        <h3>{signIn}</h3>
                        <h3>{password}</h3>
                    </div>
                    <div className="projectDetailsDetailContainer">
                        <p>{justOverview[0]}</p>
                        <p>{justOverview[1]}</p>
                        <p>{justOverview[2]}</p>
                        <p>{justOverview[3]}</p>
                    </div>
                    <div className="projectDetailspainPointContainer">
                        <h3>{justPainPoints[0]}</h3>
                        <p>{justPainPoints[1]}</p>
                        <p>{justPainPoints[2]}</p>
                        <p>{justPainPoints[3]}</p>
                    </div>
                    <div className="projectDetailsViewContainer">
                        <Link to={githubLink} className="small_button_dark">Github</Link>
                        <Link to={liveUrl} className="small_button_dark">Live</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
