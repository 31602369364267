import React from 'react'
import { FaGithub } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import slugify from 'react-slugify';
import { useInView } from 'react-intersection-observer';


export const ProjectCard = ({ title, description, imgUrl, githubLink, liveUrl, techStack, signIn, password, projectId, overview }) => {
    const { ref: projectRef, inView: projectsVisible } = useInView()

    const truncateSentence = (text) => {
        if (text.length > 35) {
            return text.slice(0, 90) + "..."
        }
    }
    return (
        <div className="project_card_section" ref={projectRef}>
            <div className={projectsVisible ? "newWaySection show" : "newWaySection hidden"}>
                <div className="newWayContainer">
                    <div className="imgContainer">
                        <img src={imgUrl} alt="" className='project_img_two' />
                    </div>
                    <div className="informationContainer">
                        <div className="projectTitleContainer">
                            <h3>{title}</h3>
                        </div>
                        <div className="projectTag">
                            <h4>{description}</h4>
                        </div>
                        <div className="techStackContainer">
                            <div className="project_icons">{techStack.map(item => (
                                <span key={item.id}>{item.icon}</span>
                            ))}</div>
                        </div>
                        <div className="appSignInContainer">
                            <div className="signinInfo">{signIn}</div>
                            <div className="passwordInfo">{password}</div>
                        </div>
                        <div className="overviewContainer">
                            <p>{truncateSentence(overview[0])}
                                <Link to={slugify(title)}>{overview[1]}</Link>
                            </p>

                        </div>
                        <div className="project_buttons_container">
                            <Link to={githubLink} className="small_button_dark"><FaGithub className='project_git' /> Github</Link>
                            <Link to={liveUrl} className="small_button_dark">Live</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


