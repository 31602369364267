import './resume.css';
import React from 'react'
import CV from '../../assets/resume_2024.pdf'
import CL from '../../assets/prb_2024_cover_letter.pdf'
import { Link } from 'react-router-dom';
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import { BsFillEnvelopeFill } from 'react-icons/bs'
import { FiGlobe } from 'react-icons/fi'

export const Resume = () => {
    return (
        <>
            <section className="resume_section container" id="resume">
                <h1 className="resume_name">Paul Black</h1>
                <div className="resume_contact_container">
                    <Link to="https://www.linkedin.com/in/paul-black-669533145/" target="_blank" rel="noreferrer">
                        <BsLinkedin class="resume_icons" /><span className="linkedIn">Linkedin</span>
                    </Link>
                    <Link to="/#hero"><FiGlobe class="resume_icons" /><span className="website">paulrblack.com</span></Link>
                    <Link to="mailto:ramoneblack@icloud.com"><BsFillEnvelopeFill class="resume_icons" /><span className="resume_emai">ramoneblack@icloud.com</span></Link>

                    <Link to="https://github.com/P-R-Black" target="_blank" rel="noreferrer">
                        <FaGithub class="resume_icons" /><span className="resume_github">Github</span>
                    </Link>
                </div>
                <div className="skills_section">
                    <h3 className="resume_skills"><strong>Objective</strong><span></span></h3>
                    <div className="objective_container">
                        <p>
                            Passionate aspiring software engineer with a solid foundation in web development
                            technologies such as HTML, CSS, JavaScript, React, and Python. Eager to leverage
                            my project experience to contribute to innovative software solutions. Excited to
                            collaborate with a forward-thinking team where I can continue to learn and grow as
                            a developer while making a meaningful impact on projects and products.
                        </p>

                    </div>
                </div>
                <div className="skills_section">
                    <h3 className="resume_skills"><strong>Skills</strong><span></span></h3>
                    <div className="resume_list_style">
                        <div className="circle"></div>
                        <ul className="skill_list">
                            <li className="resume_skill">Bootstrap</li>
                            <li className="resume_skill">CSS</li>
                            <li className="resume_skill">Django</li>
                            <li className="resume_skill">HTML</li>
                            <li className="resume_skill">JavaScript</li>
                            <li className="resume_skill">PostgreSQL</li>
                            <li className="resume_skill">Python</li>
                            <li className="resume_skill">React</li>
                            <li className="resume_skill">Redis</li>
                            <li className="resume_skill">SQL</li>
                        </ul>
                    </div>
                </div>
                <div className="resume_project_section">
                    <h3 className="resume_projects"><strong>Projects</strong><span></span></h3>
                    <div className="project_list_style">
                        <div className="circle "></div>
                        <Link to="https://melete.paulrblack.com">
                            <h4>Keep's Guide:<span>A cocktail recipe app.</span> <span><Link to="https://github.com/P-R-Black/drinks-frontend" target='_blank'><FaGithub className="projectGit" /></Link></span></h4>
                        </Link>
                    </div>
                    <div className="project_list_style">
                        <div className="circle"></div>
                        <Link to="https://sevillereport.com/">
                            <h4>The Seville Report:<span>An investment newsletter website.</span>  <span ><Link to="https://github.com/P-R-Black/SR_website" target='_blank'><FaGithub className="projectGit" /></Link></span></h4>

                        </Link>
                    </div>
                    <div className="project_list_style">
                        <div className="circle "></div>
                        <Link to="https://melete.paulrblack.com">
                            <h4>Melete:<span> A meditation app, that promotes wellness and improved mental health.</span>  <span><Link to="https://github.com/P-R-Black/DjangoMedApp " ><FaGithub className="projectGit" /></Link></span></h4>

                        </Link>
                    </div>
                </div>
                <div className="resume_experience_section">
                    <h3 className="resume_experience"><strong>Experience</strong><span></span></h3>
                    <div className="top_line_experience">
                        <h4 className="experience_title"><strong>Suits, Liability and Medical Bill Examiner</strong></h4>
                        <h4 className="experience_place">Freelance</h4>
                        <h4 className="experience_location">Florida / New York</h4>
                    </div>
                    <div className="work_dates">
                        <h4>Nov. 2014 - Present</h4>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Provide insight to attorneys regarding injury related suits, which allows them to better represent clients and focus on cases that could have a bigger impact to the firm.</li>
                        </ul>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Provide attorneys with insight into liability decisions determined by insurance accident investigations.</li>
                        </ul>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Aid physicians and medical facilities in contesting denied medical bills, resulting in significant reimbursement from insurance companies.</li>
                        </ul>
                    </div>
                    <div className="top_line_experience trainer">
                        <h4 className="experience_title"><strong> Liability and Medical Examiner, Trainer</strong></h4>
                        <h4 className="experience_place">Geico</h4>
                        <h4 className="experience_location">Woodbury, NY</h4>
                    </div>
                    <div className="work_dates">
                        <h4>Jan. 2003 - Aug. 2014</h4>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Investigated automobile accidents by contacting involved parties, witnesses, and law enforcement to collect statements and evidence.</li>
                        </ul>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Negotiated settlement of automobile claims with involved parties, adverse carriers, and legal representatives.</li>
                        </ul>
                    </div>

                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Managed medical claims by contacting injured parties, medical providers, and legal representatives to determine the severity of injury and apply applicable coverages.</li>
                        </ul>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Worked with arbitration team to contest complaints of unpaid medical claims, which resulted in the company saving hundreds of thousands of dollars in late fees and interest payments.</li>
                        </ul>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Trained new hires on how to investigate claims, negotiate liability, and resolve conflicts.</li>
                        </ul>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Led development of new training module that decreased time spent in training from several months to four weeks.</li>
                        </ul>
                    </div>
                </div>
                <div className="resume_education_section">
                    <h3 className="education_experience"><strong>Education</strong><span></span></h3>
                    <div className="education_top_line">
                        <h4 className="experience_title"><strong>Bachelor of Science</strong></h4>
                        <h4 className="education_place education_place_top"><strong>Long Island University</strong></h4>
                        <h4 className="experience_location">Brooklyn, NY</h4>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="education_body">
                            <li>Finance</li>
                        </ul>
                    </div>
                    <div className="education_top_line">
                        <h4 className="experience_title"><strong>Bachelor of Arts</strong></h4>
                        <h4 className="education_place"><strong>Long Island University</strong></h4>
                        <h4 className="experience_location">Brooklyn, NY</h4>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="education_body">
                            <li>Business Management</li>
                        </ul>
                    </div>
                </div>
            </section>
            <div className="container download_resume">
                <a href={CV} download className='button_light resume'>{"Download Resume"}</a>
                <a href={CL} download className='button_dark resume'>{"Download Cover Letter"}</a>
            </div>
        </>
    )
}
