import React from 'react';
import './projects.css';
import { ProjectCard } from './ProjectCard';
import { SideProjects } from './SideProjects';

import { projects } from './projectInfoApi';
import { skill_builders } from './projectInfoApi';
import { useInView } from 'react-intersection-observer';


export const Projects = () => {
    const { ref: projectTitleRef, inView: projectTitlesVisible } = useInView()
    const { ref: sideProjectTitleRef, inView: sideProjectTitlesVisible } = useInView()



    return (
        <section id="projects" className="project_section_two">
            <div ref={projectTitleRef} className="project_section_title_container">
                <h5 className={projectTitlesVisible ? "project_sectionH5 show" : "project_sectionH5 hidden"}>My Recent</h5>
                <h2 className={projectTitlesVisible ? "project_sectionH2 show" : "project_sectionH2 hidden"}>Projects</h2>
            </div>

            <div className="container">
                <div className="projects_container">
                    {projects.map((project, index) => {
                        return (
                            <ul id="projectCard" key={index}>
                                <li key={project.projectId} >
                                    <ProjectCard
                                        {...project}
                                    />
                                </li>

                            </ul>
                        )
                    })}
                </div>
            </div>
            <div className="container side_project_container" ref={sideProjectTitleRef}>
                <h2 className={sideProjectTitlesVisible ? "skill_builders show" : "skill_builders hidden"}><strong>Skill Builders</strong> | Small projects to learn the basics</h2>
                <div className={sideProjectTitlesVisible ? "side_project_divider show" : "side_project_divider hidden"}></div>
                <div className='side_project_section'>
                    {skill_builders.map((side) => {
                        return (
                            <ul key={side.projectId}>
                                <li >
                                    <SideProjects
                                        {...side}
                                    />
                                </li>
                            </ul>
                        )
                    })}
                </div>
            </div>
        </section>


    )
}


