import React, { useRef, useState } from 'react'
import './contact.css'
import { CgSpinnerTwo } from 'react-icons/cg'
import emailjs from '@emailjs/browser';
import { useInView } from 'react-intersection-observer';

export const Contact = () => {

    const form = useRef();
    const [emailSent, setEmailSent] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { ref: contactTitleRef, inView: contactTitlesVisible } = useInView()
    const { ref: formRef, inView: formVisible } = useInView()

    const isPending = () => {
        if (isLoading === false) {
            setIsLoading(true);
        }

        if (isLoading === true) {
            setIsLoading(false);
        }


    }

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                setEmailSent('Your email has been sent successfully.');
                setIsLoading(false);
                console.log(result.text);
            }, (error) => {
                setEmailSent('There was an issue, the email was not sent.')
                setIsLoading(false);
                console.log('error.text', error.text);
            });

        e.target.reset();

    };


    return (
        <section id="contact" className='contact_section'>
            <div ref={contactTitleRef} className="contact_section_title_container">
                <h5 className={contactTitlesVisible ? "conctact_sectionH5 show" : "conctact_sectionH5 hidden"}>Get in Touch</h5>
                <h2 className={contactTitlesVisible ? "conctact_sectionH2 show" : "conctact_sectionH2 hidden"}>Contact Me</h2>
            </div>
            <div ref={formRef}>
                <form ref={form} onSubmit={sendEmail} className="contact_form" autoComplete="off">

                    <input className={formVisible ? "inputName show" : " inputName hidden"} autoComplete="on" type="text" name="name" placeholder="Your Name" required />
                    <input className={formVisible ? "inputEmail show" : " inputEmail hidden"} autoComplete="on" type="email" name="email" placeholder="Your Email" required />
                    <textarea className={formVisible ? "inputMessage show" : " inputMessage hidden"} autoComplete="off" name="message" placeholder="Your Message" id="" cols="30" rows="10" required />

                    <div className='email_sent'>{emailSent}</div>
                    {isLoading ? <CgSpinnerTwo className='spinner' /> : ""}

                    <button type="submit" className='button_light'
                        onClick={isPending}>
                        Send Message
                    </button>

                </form>

            </div>

        </section>
    );
};

