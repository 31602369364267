import React from 'react'

import { Hero } from '../../components/hero/Hero'
import { About } from '../../components/about/About'
import { Expertise } from '../../components/expertise/Expertise';
import { Projects } from '../../components/projects/Projects';
import { Contact } from '../../components/contact/Contact';


export const Home = () => {


  return (
    <>
      <Hero />
      <About />
      <Expertise />
      <Projects />
      <Contact />
    </>
  )
}
