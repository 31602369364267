import React from 'react'
import CV from '../../assets/resume_2024.pdf'

export const CTA = () => {
  return (
    <div className="cta">
      <a href={CV} download className='button_light'>{"Download Resume"}</a>
      <a href="#contact" className='button_dark'>{"Let's Connect"}</a>
    </div>
  )
}
