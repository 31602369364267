import slugify from 'react-slugify';
import projImg1 from '../../assets/sevillereport.png';
import projImg3 from '../../assets/melete.png';
import projImg4 from '../../assets/keepsguide.png';
import rareFindsHero from '../../assets/rareFindsHero.png';

import interviewApp from '../../assets/interviewApp.jpg';

import { FaPython } from 'react-icons/fa';
import { SiDjango } from 'react-icons/si';
// import { TbSql } from 'react-icons/tb';
import { SiPostgresql } from 'react-icons/si';
import { FaNodeJs } from "react-icons/fa";
import { SiMongodb } from "react-icons/si";


import { FaHtml5 } from 'react-icons/fa';
import { FaCss3Alt } from 'react-icons/fa';
import { DiJavascript1 } from 'react-icons/di';
import { FaReact } from 'react-icons/fa';
import { SiTypescript } from "react-icons/si";

export const projects = [
    {
        title: 'Rare Finds',
        slug: slugify('Rare Finds'),
        description: 'A record shop for rare vinyl',
        imgUrl: rareFindsHero,
        techStack: [
            { id: 1, icon: <FaReact /> },
            { id: 7, icon: <SiTypescript /> },
            { id: 2, icon: <FaHtml5 /> },
            { id: 3, icon: <FaCss3Alt /> },
        ],
        signIn: '',
        password: '',
        projectId: 5,
        githubLink: 'https://github.com/P-R-Black/rarefinds',
        liveUrl: 'http://rarefinds.paulrblack.com',
        overview: ['This is a three section website that started from a client\'s desire to have a small web presence. The client\'s objective was to make record collectors aware of his record store that collects and sells hard to find vinyl records.\nThe website was configured with a hero section, an about section and a contact section. The site owner also wanted to collect email addresses in order to distribute a weekly newsletter, which will provide subscribers with information on newly acquired records that he is willing to sell.\nA full-fledged e-commerce site would have been ideal, but the client wanted a small site that allowed other record collectors from around the world to contact him.', ' read more >'],
        painPoints: ["Pain Points", "This was the first time I used TypeScript on a project, and understanding the TypeScript errors did take some time to adjust to and correct, but it was worth it. On my last React.js project, a lot of time was spent towards the end of the project cleaning up small issues that prevented the app from working efficiently.", "Another small issue with building this site was figuring out a way to collect emails to a list. MailChimp was the first option that came to mind. I eventually learned that in order to use the MailChimp API there would need to be a backend component to the site. In the end, we settled on Mailer Lite. The setup wasn't difficult, and it met all of the clients needs."],


    },
    {
        title: 'Keep\'s Guide',
        slug: slugify('Keep\'s Guide'),
        description: 'A guide for creating great cocktails',
        imgUrl: projImg4,
        techStack: [
            { id: 1, icon: <FaReact /> },
            { id: 2, icon: <FaHtml5 /> },
            { id: 3, icon: <FaCss3Alt /> },
            { id: 4, icon: <FaNodeJs /> },
            { id: 5, icon: <SiMongodb /> }],
        signIn: '',
        password: '',
        projectId: 4,
        githubLink: 'https://github.com/P-R-Black/SR_website/tree/master',
        liveUrl: 'https://keepsguide.paulrblack.com/',
        overview: ['This project was done with the goal of understanding the React framework. I created an API using cocktail recipes, and learned how to map, filter, find, and display API data using the React.\nI also gained experience working with Node.js, Express, and MongoDB. I used MongoDb to host the names of the "Drink of the Day", which is a random cocktail selected everyday at 3:00AM.', ' read more >'],
        painPoints: ['Pain Points', 'Knowing when and how to use useState, useEffect, useCallback and the other React hooks, as well as understanding how React passes information from component to component took some time to understand.']


    },
    {
        title: 'The Seville Report',
        slug: slugify('The Seville Report'),
        description: 'An investment newsletter website',
        imgUrl: projImg1,
        techStack: [
            { id: 1, icon: <SiDjango /> },
            { id: 2, icon: <FaPython /> },
            { id: 3, icon: <DiJavascript1 /> },
            { id: 4, icon: <FaHtml5 /> },
            { id: 5, icon: <FaCss3Alt /> },
            { id: 6, icon: <SiPostgresql /> }
        ],
        signIn: '',
        password: '',
        projectId: 1,
        githubLink: 'https://github.com/P-R-Black/SR_website/tree/master',
        liveUrl: 'https://sevillereport.com/',
        overview: [
            'This is a website for an investment newsletter, it was my first project where I worked on the frontend, backend, and hosted it to a server without following a tutorial.\nIn addition to the technology listed above, I also used Redis to cache data and reduce database calls, NGINX and Gunicorn for hosting the site on a Digital Ocean droplet and the Stripe payment system to process newsletter purchases.\n', ' read more>'],
        painPoints: ['Pain Points', 'The biggest challenges for this project were setting up the Stripe payment gateway and setting up the droplet, server, and database with Digital Ocean to host the site.'],

    },
    {
        title: 'Melete',
        slug: slugify('Melete'),
        description: 'A meditation app',
        imgUrl: projImg3,
        techStack: [
            { id: 1, icon: <SiDjango /> },
            { id: 2, icon: <FaPython /> },
            { id: 3, icon: <DiJavascript1 /> },
            { id: 4, icon: <FaHtml5 /> },
            { id: 5, icon: <FaCss3Alt /> },
            { id: 6, icon: <SiPostgresql /> }],
        signIn: 'Demo Sign In: tonystark@avengers.com',
        password: 'Demo Password: IronMan123',
        projectId: 2,
        githubLink: 'https://github.com/P-R-Black/new_melete',
        liveUrl: 'https://melete.paulrblack.com',
        overview: ['This is a meditation site I created that includes a user account feature, which was implemented in Django. Witht his project I gained more experience creating models, views and templates in Django. I also learned how to create custom forms for recieiving and validating user data.\n Adding the user account feature allowed me to better understand how user accounts are created and maintened by large companies, and how to serve useful information to the user about their account.', ' read more >'],
        painPoints: ['Pain Points', 'The biggest challenges for this project were creating the custom user data, such as amount of time spent meditating over the past day, week, month, and year, and displaying that information to the user; and hosting the site on Digital Ocean. Figuring out how to host a second Django projects on one Digital Ocean droplet while sharing a database did take some time to get right.']

    },
]

export const skill_builders = [
    {
        title: 'Interview Prep',
        slug: slugify('Interview Prep'),
        description: 'React and Django Interview Prep',
        imgUrl: interviewApp,
        techStack: [
            { id: 1, icon: <FaReact /> },
            { id: 2, icon: <FaHtml5 /> },
            { id: 3, icon: <FaCss3Alt /> }
        ],
        projectId: 3,
        githubLink: 'https://github.com/P-R-Black/Programming-Interview-Prep',
        liveUrl: 'http://interviewapp.paulrblack.com',
        overview: ['I had previously done React projects in the FreeCodeCamp Front End Development Libraries course, but this project was my first time thinking of an idea and implementing that idea in React on my own. I had seen several tutorias on creating quiz apps, and figured I would make a quiz app in React that test my knowledge on the two framworks I had been learning, Reach and Django.', ' read more >'],
        painPoints: ['Pain Points', 'The biggest challenge for this project was learning how to properly update a useState hook and not have multiple re-renders that cause the site to crash and my computer\'s fans to turn on.']

    }
]