import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import { Home } from "../pages/home/Home";
import { ResumePage } from "../pages/resumePage/ResumePage";
import { ProjectDetailsPage } from "../pages/projectDetailsPages/ProjectDetailsPage";




export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { path: "", element: <Home /> },
            { path: "resume", element: <ResumePage /> },
            { path: ":project", element: <ProjectDetailsPage /> }
        ]
    }
])