import React from 'react'

import { Logo } from '../logo/Logo'
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import './footer.css'

export const Footer = () => {
  let date = new Date()
  let year = date.getFullYear()

  return (
    <section className="footer_section" id="footer">
      <div className="container footer_container">
        <Logo />
        <ul className="footer_links">
          <li className="footer_link"><a href="/#about_section">About</a></li>
          <li className="footer_link"><a href="/#expertise">Expertise</a></li>
          <li className="footer_link"><a href="/#projects">Projects</a></li>
          <li className="footer_link"><a href="/#contact">Contact</a></li>
        </ul>
        <div className="footer_social">
          <a href="https://www.linkedin.com/in/paul-black-669533145/" target="_blank" rel="noreferrer"><BsLinkedin /></a>
          <a href="https://github.com/P-R-Black" target="_blank" rel="noreferrer"><FaGithub /></a>
        </div>
        <div className="footer_copyright">
          <small>&copy;PB. {year} All Rights Reserved</small>
        </div>

      </div>
    </section>
  )
}
