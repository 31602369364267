import React, { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer';
import './hero.css'
import { HeroSocial } from './HeroSocial'
import { CTA } from './CTA'


export const Hero = () => {
  const { ref: heroRef, inView: elementVisible } = useInView()
  const { ref: backgroundRef, inView: backgroundVisible } = useInView()


  const [startCount, setStarCount] = useState(0);
  // const heroRef = useRef()
  // const [elementVisible, setElementVisible] = useState()


  let i = 0;
  const NewStarMaker = () => {
    let count = 150;
    let scene = document.querySelector(".hero_section")

    while (i < count) {
      let star = document.createElement('div');
      let x = Math.floor(Math.random() * window.innerWidth);
      let y = Math.floor(Math.random() * window.innerHeight);
      let duration = Math.random() * 10;
      let size = Math.random() * 2
      star.classList.add('starScene')
      star.style.left = x + 'px'
      star.style.top = y + 'px'
      star.style.width = 1 + size + 'px'
      star.style.height = 1 + size + 'px'

      star.style.animationDuration = 5 + duration + 's';
      star.style.animationDelay = duration + 's';
      scene.appendChild(star)
      i++;


    }
    for (let j = 0; j < startCount; j++) {
      return (
        <div className="starScene"></div>
      )
    }
  }


  useEffect(() => {
    NewStarMaker()
    setStarCount(150)

  })

  return (
    <section id="hero" ref={backgroundRef} className={`${backgroundVisible} ? hero_section show_background : hero_section hide_background`}>
      <div className="container">
        <div className="hero_container">
          <div className={`${elementVisible} ? innerHeroContainer show_container : innerHeroContainer hide_container`}>
            <HeroSocial />
            <div ref={heroRef} className="bubble"></div>
            <div className="introduction">
              <h1 className="hello">I'm <span className="first_initial">Paul Black</span>
              </h1>
              <h2 className="developer">{"Web Developer"}
              </h2>
              <h4>I like learning new technologies and developing products for the web.
              </h4>
              <CTA />
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}
