import React from 'react'
import { ProjectDetails } from '../../components/projects/projectDetails/ProjectDetails'


export const ProjectDetailsPage = () => {
    return (
        <>
            <ProjectDetails />
        </>
    )
}
