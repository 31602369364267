import React from 'react';
import { useInView } from 'react-intersection-observer';

import './about.css';

export const About = () => {

    const { ref: aboutRef, inView: aboutTitlesVisible } = useInView()
    const { ref: storyRef, inView: storyVisible } = useInView()
    const { ref: eduRef, inView: educationVisible } = useInView()


    return (
        <section className="about_section" id="about_section">
            <div ref={aboutRef} className="about_section_title_container">
                <h5 className={aboutTitlesVisible ? "about_sectionH5 show" : " about_sectionH5 hidden"}>Just a Little</h5>
                <h2 className={aboutTitlesVisible ? "about_sectionH2 show" : "about_sectionH2 hidden"}>About Me</h2>
            </div>



            <div className="outer_container">
                <div ref={storyRef} className="container about_container">
                    <blockquote className={storyVisible ? "story show" : "story hidden"} id="story">
                        <p className={storyVisible ? "about_me_inner show" : "about_me_inner hidden"}>
                            I worked in insurance for over 10 years, with a focus on auto accident investigations.
                            With the skills gained from working in insurance, I was able to work as a fulltime freelancer for the past
                            nine years. Researching how to web scrape and automate mundane task introduced me to programming with Python.
                            After creating that first web scraper, I dove deeper into programming, learning different languages and frameworks,
                            building projects, and even completing several online courses. Today, I'm ready to put what I've learned to work for others.
                        </p>
                        <button className='button_light'><a href="#contact">Let's Connect</a></button>
                    </blockquote>
                </div>


                <div ref={eduRef} className="container education_container">
                    <blockquote className={educationVisible ? "education show" : "education hidden"} id="education">
                        <ul className="about_me_inner_list">
                            <li>B.A. Business Management: <span> Long Island University, Brooklyn, NY</span></li>
                            <li>B.S. Finance: <span> Long Island University, Brooklyn, NY</span></li>
                        </ul>
                    </blockquote>
                    <blockquote className={educationVisible ? "other_edu show" : "other_edu hidden"} id="other_edu">
                        <ul className="about_me_inner_list about_me_ul">
                            <li className="ul_title">FreeCodeCamp</li>
                            <li className="ul_list">Responsive Web Design</li>
                            <li className="ul_list">JavaScrip Algorithms and Data Structures</li>
                            <li className="ul_list">Front End Development Libraries</li>
                            <li className="ul_title">Harvard CS50</li>
                            <li className="ul_list">Course Completed</li>
                        </ul>
                    </blockquote>
                </div>
            </div>
        </section>
    )
}
