import React from 'react'
import './hero.css'

import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'


export const HeroSocial = () => {
  return (
    <div className="hero_socials">
      <a href="https://www.linkedin.com/in/paul-black-669533145/"
        target="_blank" rel="noreferrer"><BsLinkedin />
      </a>
      <a href="https://github.com/P-R-Black" target="_blank" rel="noreferrer"><FaGithub /></a>
    </div>
  )
}
