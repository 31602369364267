import React from 'react'
import { Resume } from '../../components/resume/Resume'

export const ResumePage = () => {
    return (
        <>
            <Resume />
        </>
    )
}
