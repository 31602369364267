import React, { useState, useRef, useEffect } from 'react'
import './logo.css'


export const Logo = () => {

  const theLogo = 'Paul B.'
  const logoRef = useRef();
  const [y, setY] = useState()


  const getPosition = () => {
    const y = logoRef.current.offsetTop;
    setY(y)
  }


  useEffect(() => {
    getPosition();
  }, [])


  return (
    <>
      <a href="/" >
        <div ref={logoRef} className={`${y < 50 ? 'logo' : 'footer_logo'}`}>
          <div className="first">{theLogo}</div>
        </div>
      </a>
    </>

  )
}